import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlternateTable } from '@app/shared/types/alternate-table';

@Component({
  selector: 'app-alternate-details-grid',
  templateUrl: './alternate-details-grid.component.html',
  styleUrls: ['./alternate-details-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlternateDetailsGridComponent {
  maxColNumber!: number;
  gridTable!: AlternateTable;
  @Input() detailTranslocoPath!: string;
  @Input()
  set table(value: AlternateTable) {
    const itemsLength = value.rows.map((row) => row.items.length);
    this.maxColNumber = itemsLength.reduce((a, b) => Math.max(a, b));
    value.rows.forEach((row) => {
      row.items.forEach((item, index) => {
        const isLastInRow: boolean = row.items.length - 1 === index;
        const currentRowItemsLength = row.items.length;
        if (isLastInRow && currentRowItemsLength < this.maxColNumber) {
          const colspan = this.maxColNumber - currentRowItemsLength;
          item.colspan = colspan + 1;
        } else {
          item.colspan = 1;
        }
      });
    });
    this.gridTable = value;
  }
}
