import { AlertTypeColors } from '@app/shared/types/alert';
import { PaymentCodeTypes } from '@app/shared/types/details';

// eslint-disable-next-line no-shadow
export enum ClaimStatus {
  TO_BE_ASSIGNED = 'TO_BE_ASSIGNED', // S'affecter la demande.
  TO_BE_CHECKED = 'TO_BE_CHECKED', // Valider le controle pour visa. if there is no retours.
  // enable editing / if in edit mode -> renvoyer les retours au partenaire bancaire
  TO_BE_SIGNED = 'TO_BE_SIGNED', // A viser --> Viser la demande. //
  SIGNED = 'SIGNED', // Visé --> // pas de bouton.
  DRAFT = 'DRAFT',
  RECEIVED = 'RECEIVED',
  TO_BE_FIXED = 'TO_BE_FIXED',
  CANCELLED = 'CANCELLED',
  TO_BE_COMPLETED = 'TO_BE_COMPLETED',
  REJECTED = 'REJECTED',
  TO_BE_PAID = 'TO_BE_PAID',
  PAID = 'PAID',
  CANCELLED_TECH = 'CANCELLED_TECH',
  CANCELLED_BY_MANAGER = 'CANCELLED_BY_MANAGER'
}

// eslint-disable-next-line no-shadow
export const enum SamplingStatus {
  SAMPLED = 'SAMPLED',
  NOT_SAMPLED = 'NOT_SAMPLED',
  MANDATORY_MANUAL = 'MANDATORY_MANUAL',
}

// eslint-disable-next-line no-shadow
export const ClaimStatusTranslation: { [type in ClaimStatus]: string } = {
  [ClaimStatus.TO_BE_ASSIGNED]: 'dashboard.claim-status.to-be-assigned',
  [ClaimStatus.TO_BE_CHECKED]: 'dashboard.claim-status.to-be-checked',
  [ClaimStatus.TO_BE_SIGNED]: 'dashboard.claim-status.to-be-signed',
  [ClaimStatus.SIGNED]: 'dashboard.claim-status.signed',
  [ClaimStatus.DRAFT]: '',
  [ClaimStatus.RECEIVED]: '',
  [ClaimStatus.TO_BE_FIXED]: '',
  [ClaimStatus.CANCELLED]: '',
  [ClaimStatus.TO_BE_COMPLETED]: 'dashboard.claim-status.to-be-completed',
  [ClaimStatus.REJECTED]: 'dashboard.claim-status.rejected',
  [ClaimStatus.TO_BE_PAID]: 'dashboard.claim-status.to-be-paid',
  [ClaimStatus.PAID]: 'dashboard.claim-status.paid',
  [ClaimStatus.CANCELLED_TECH]: 'dashboard.claim-status.cancelled-tech',
  [ClaimStatus.CANCELLED_BY_MANAGER]: 'dashboard.claim-status.cancelled-manager'
};

export interface SearchClaims {
  items: { [status: string]: ClaimExcerpt[] }
  message: string;
  messageError: string;
}

export interface ClaimExcerpt {
  bankCode: string;
  bankName: string;
  companyName: string;
  contractReference: string;
  createdDate: string;
  claimCreationDateInBRP: Date;
  creditEventLabel: string;
  firstLawyer: string;
  id: string;
  financingContractId: string;
  lastStatusDate: string;
  loanAmount: number;
  partnerRequestId: string;
  paymentTypeVp: string;
  paymentTypeVf: string;
  samplingStatus: SamplingStatus;
  siren: string;
  status: ClaimStatus;
  statusLabel: string;
  compensationEndDate: string;
  userAssigned: string;
  userAssignedFirstName: string;
  userAssignedLastName: string;
  userAssignedEmail: string;
  claimAlert?: AlertTypeColors;
  remainingDays?: number;
  motif: string;
  subMotif: string;
  claimType: string;
  lastClaimUpdateDate: string;
  iziClaimId: number;
  paymentCodeType: PaymentCodeTypes;
  claimStatus: ClaimStatus;
  bankCompensatedAmount: number;
  claimCreationDate: Date;
  creditEventType: string;
  warrantyContractId: string;
}

export interface OtherClaim {
  iziClaimId: number;
  companyName: string;
  siren: string;
  creditEventLabel: string;
  motif: string;
  subMotif: string;
  paymentTypeVp: string;
  paymentTypeVf: string;
  paymentCodeType: PaymentCodeTypes;
  claimStatus: ClaimStatus;
  financingContractId: string;
  loanAmount: number;
  bankName: string;
  id: string;
  bankCode: string;
  bankCompensatedAmount: number;
  claimCreationDate: Date;
  claimCreationDateInBRP: Date;
  contractReference: string;
  creditEventType: string;
  warrantyContractId: string;
  creditEventMotif: string;
  creditEventSubMotif: string;
  claimType: string;
  installmentPaymentRequest: boolean;
  ctxWithAbandonRequest: boolean;
  creditEvent: string;
  reasonCreditEvent: string;
  subReasonCreditEvent: string;
  createdBy: string;
  lastModifiedDate: Date;
  lastModifiedBy: string;
  lastModificationDateByPartner: Date;
  userAssigned: string;
  motifRejet: string;
  libellMotifRejet: string;
}

