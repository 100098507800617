<div class="alternate-details" *ngIf="gridTable">
  <table>
      <tr *ngFor="let row of gridTable.rows">
        <ng-container *ngFor="let item of row.items; let itemsIndex = index">
          <td *ngIf="item.value" [attr.colspan]="item.colspan">
            <div class="alternate-details__box info-box">
              <div class="alternate-details__box--label">{{ item.label | transloco }}</div>
              <div class="alternate-details__box--value" [appDisplayMode]="item" [detailTranslocoPath]="detailTranslocoPath">{{ item.value }}</div>
            </div>
          </td>
        </ng-container>
      </tr>
  </table>
</div>
