<div class="steps">
  <ul>
    <li class="steps-item" *ngFor="let stepStatus of stepsStatus">
      <a >
        <mat-icon [ngStyle]="{ 'color': stepStatus.statusColor }"> check_circle </mat-icon>
        <span> {{ stepStatus.statusLable | transloco }} </span>
      </a>
    </li>
  </ul>
</div>
