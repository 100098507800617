<div class="contact-card">
  <div *ngFor="let item of cardDetails.items" class="contact-card__row">
    <div *ngIf="item.icon" class="contact-card__row--icon">
      <mat-icon>{{ item.icon }}</mat-icon>
    </div>
    <div class="contact-card__row--text">
      <a *ngIf="item.isEmail; else text" href="mailto:{{item.text}}">{{ item.text }}</a>
      <ng-template #text> <p>{{ item.text }}</p></ng-template>
    </div>
  </div>
</div>
