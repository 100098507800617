import { CanActivate, Router, UrlTree } from '@angular/router';
import { PAGE_URL } from '@app/app.constants';
import { Observable, of } from 'rxjs';
import { catchError, mapTo } from 'rxjs/operators';
import { AccountService } from '@app/core/auth/account.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UserAccessGuard implements CanActivate {
  constructor(private accountService: AccountService, private router: Router) {}

  canActivate = (): Observable<boolean | UrlTree> =>
    this.accountService.getCurrentUser().pipe(
      mapTo(true),
      catchError(() => {
        return of(this.router.createUrlTree(['/', PAGE_URL.ACCESS_DENIED]));
      })
    );
}
