<div class="info-card">
  <ul class="info-card__list">
    <ng-container *ngFor="let item of infoList">
      <li class="info-card__list-item" *ngIf="item.value">
        <p *ngIf="item.label" class="info-card__list-item--label">
          {{ item.label }} :
        </p>
        <p *ngIf="item.value" [ngClass]="{'info-card__list-item--value': item.label}">
          <ng-container *ngIf="!item.redirectUrl; else itemLink">
            <span [appDisplayMode]="item">{{ item.value }}</span>
          </ng-container>
          <ng-template #itemLink>
            <a [ngClass]="{'highlighted': item.highlighted }" [routerLink]="item.redirectUrl">{{ item.value }} </a>
          </ng-template>
        </p>
      </li>
    </ng-container>
  </ul>
</div>

