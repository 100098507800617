import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { PAGE_URL } from '@app/app.constants';
import { AccountService } from '@app/core/auth/account.service';

@Injectable({
  providedIn: 'root',
})
export class AuthIsamGuard implements CanActivate {
  constructor(public accountService: AccountService, public router: Router) {}

  canActivate = (
    _route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): true | UrlTree =>
    this.accountService.isAuthenticatedIsam() ||
    this.router.createUrlTree([PAGE_URL.SIGNIN], {
      queryParams: { redirectUri: state.url },
    });
}
