<div class="back-button">
  <a
    bpi-button
    [routerLink]="redirectUrl"
    icon="true"
    color="secondary"
    [title]="buttonText">

    <mat-icon>arrow_back</mat-icon>
  </a>
  <span class="back-button__text">
    <strong> {{ buttonText }} </strong>
  </span>
</div>
