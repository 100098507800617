<div class="info-box" [ngClass]="{'alert': detail.hasAlert}">
  <div class="claim-detail__label">{{ detail.label| transloco }}</div>

  <ng-template [ngIf]="typeService.isDocumentDetail(detail)" [ngIfElse]="value">

    <ng-container *ngIf="this.downloadUrl$ | async as url; else spinner">
      <a
      class="claim-detail__value"
      [href]="url"
      [title]="detail.value.documentName || detail.label | transloco"
      target="_blank"
      rel="noopener,noreferrer"
    >
      <mat-icon>description</mat-icon>
      <span>{{ detail.value.documentName || detail.label| transloco }}</span>
    </a>
    </ng-container>
    <ng-template #spinner>
      <mat-spinner diameter="20" *ngIf="this.hasValue"></mat-spinner>
    </ng-template>
  </ng-template>

  <ng-template #value>
    <div class="claim-detail__value" [appDisplayMode]="detail" [detailTranslocoPath]="detailTranslocoPath" >{{ detail.value }}</div>
  </ng-template>

</div>
