import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { combineLatest } from 'rxjs';

import { environment } from '../environments/environment';
import { authConfig } from './core/auth/config';

const isUrlAbsolute = (url?: string) => url && url.match('^(?:[a-z]+:)?//');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    private oauthService: OAuthService,
    private router: Router,
    private titleService: Title,
    private translocoService: TranslocoService
  ) {
    combineLatest([
      this.router.events,
      this.translocoService.selectTranslation(''),
    ]).subscribe(([event]) => {
      if (event instanceof NavigationEnd && !event.url.includes('/sso/code?')) {
        const currentUrl = event.urlAfterRedirects;
        const claims = new RegExp('/claim/.+/.+');
        const contracts = new RegExp('/contracts');
        const contractDetails = new RegExp('/contracts/.+/.+');
        const dashboard = new RegExp('/dashboard');
        const groupsPayment = new RegExp('/contracts/.+/.+');

        let title = '';
        switch (true) {
          case claims.test(currentUrl):
            title = 'page-titles.claim-details';
            break;
          case contractDetails.test(currentUrl):
            title = 'page-titles.contract-details';
            break;
          case contracts.test(currentUrl):
            title = 'page-titles.contrats';
            break;

          case dashboard.test(currentUrl):
            title = 'page-titles.dashboard';
            break;
          case groupsPayment.test(currentUrl):
            title = 'page-titles.groups-payment';
            break;
          default:
            title = `page-titles.bpifrance`;
            break;
        }

        setTimeout(() => {
          this.titleService.setTitle(this.getTranslation(title));
        }, 200);
      }
    });

    this.oauthService.configure(authConfig);
    this.oauthService.events.subscribe((event) => {
      if (
        event.type === 'token_received' &&
        !isUrlAbsolute(this.oauthService.state)
      ) {
        this.router.navigateByUrl(
          this.oauthService.state
            ? decodeURIComponent(this.oauthService.state)
            : '/'
        );
      }
    });

    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService
      .loadDiscoveryDocument(
        `${authConfig.issuer}/${environment.sso.discoveryDocumentEndpoint}`
      )
      .then(() => {
        return this.oauthService.tryLogin();
      })
      .then(() => {
        this.oauthService.setupAutomaticSilentRefresh();
      });
  }

  getTranslation(key: string): string {
    return this.translocoService.translate(key);
  }
}
