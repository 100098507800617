import {Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {UserRole} from "@app/shared/types/user";
import {AccountService} from "@app/core/auth/account.service";

@Directive({
  selector: '[canVisualize]'
})
export class CanVisualizeDirective implements OnInit {
  @Input() canVisualize!: UserRole[];


  constructor(private accountService: AccountService,
              private templateRef: TemplateRef<never>,
              private viewContainerRef: ViewContainerRef) { }

  ngOnInit() {
    this.accountService.getCurrentUser().subscribe(currentUser => {
      if (this.canVisualize.includes(currentUser.role)) {
        this.viewContainerRef.createEmbeddedView(this.templateRef);
      }
    });
  }
}
