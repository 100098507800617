import { Component } from '@angular/core';

@Component({
  selector: 'app-christmas-lights',
  templateUrl: './christmas-lights.component.html',
  styleUrls: ['./christmas-lights.component.scss'],
})
export class ChristmasLightsComponent {
  lightsCount = new Array(40);
}
