import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { SnackbarService } from '@app/shared/services/snackbar.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class HttpErrorInterceptor implements HttpInterceptor {
  generalErrorMessage =
    'Votre demande a échouée, veuillez réessayer ultérieurement.';
  constructor(private snackbarService: SnackbarService) {}
  intercept(
    request: HttpRequest<{}>,
    next: HttpHandler
  ): Observable<HttpEvent<{}>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403 || error.status === 500)
          this.snackbarService.showErrorMessage(this.generalErrorMessage);
        return throwError(error);
      })
    );
  }
}
