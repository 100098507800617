<app-header [hasButtons]="true" [fixed]="false"></app-header>

<div
  class="generic-wrapper"
  [class.generic-wrapper--vertical]="vertical"
  [class.generic-wrapper--centered]="centered"
>
  <img *ngIf="illustration" class="generic__illustration" [src]="illustration" />
  <div
    [class.generic-container-standalone]="!illustration"
    [class.generic-container]="illustration"
  >
    <h1 *ngIf="title" class="title">{{ title | transloco }}</h1>
    <h2 *ngIf="subTitle" class="generic__error-title">{{ subTitle | transloco }}</h2>
    <p *ngIf="contentText" class="generic__error-message">{{ contentText | transloco }}</p>
    <main class="custom__content">
      <ng-content></ng-content>
    </main>
    <ul class="generic__actions" *ngIf="redirectionLink || externalRedirectionLink">
      <li *ngIf="externalRedirectionLink">
        <a bpi-button type="button" appearance="secondary" [href]="externalRedirectionLink">{{
          externalRedirectionLinkText | transloco
        }}</a>
      </li>
      <li *ngIf="redirectionLink">
        <a bpi-button type="button" appearance="primary" [routerLink]="redirectionLink">{{
          redirectionLinkText | transloco
        }}</a>
      </li>
    </ul>

    <a *ngIf="tertiaryLink" class="generic__tertiary-link" href="tertiaryLink">{{ tertiaryLinkText | transloco }}</a>
  </div>
</div>
