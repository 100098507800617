import { Component } from '@angular/core';
import { environment } from '@env/environment';

@Component({
  selector: 'app-access-denied-page',
  templateUrl: './access-denied-page.component.html',
  styleUrls: ['./access-denied-page.component.scss'],
})
export class AccessDeniedPageComponent {
  supportEmailRef = `mailto:${environment.support.email}`;
}
