export const PAGE_URL = {
  // Cockpit
  ADMIN: 'administration',
  DASHBOARD: 'dashboard',
  SIGNIN: 'login',
  CONTRACTS: 'contracts',
  CLAIM: 'claim',
  GROUPS_PAYEMENT:'groups-payment',
  ERROR: 'erreur',
  ACCESS_DENIED: 'acces-non-autorise',
  HOME: 'home',
};
