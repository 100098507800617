// eslint-disable-next-line no-shadow
export enum DetailDataType {
  BOOLEAN,
  DATE,
  CURRENCY,
  PERCENTAGE,
  CLAIM_SAMPLE,
  MULTIPLE_CHOICES,
  ATTACHMENT,
  AMORTIZATION,
  PERIOD,
  MONTH_DURATION,
  DATATABLE
}
