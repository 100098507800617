import { Component, Input } from '@angular/core';
import { ContactCard } from '@app/shared/types/contact-card';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  styleUrls: ['./contact-card.component.scss'],
})
export class ContactCardComponent {
  @Input() cardDetails!: ContactCard;
}
