import { ErrorHandler, Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular';
import { environment } from 'src/environments/environment';

Sentry.init({
  dsn: environment.sentryDSN,
  environment: environment.name,
  release: environment.release,
});

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(error: any) {
    if (!window.location.origin.includes('localhost')) {
      Sentry.captureException(error.originalError || error);
    }

    if (!environment.production) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  }
}
