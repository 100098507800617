import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private oauthService: OAuthService) {}

  intercept(
    request: HttpRequest<{}>,
    next: HttpHandler
  ): Observable<HttpEvent<{}>> {
    if (
      !request ||
      !request.url ||
      (request.url.startsWith('http') &&
        !request.url.startsWith(environment.apiBase))
    ) {
      return next.handle(request);
    }

    if (this.oauthService.hasValidIdToken()) {
      const token = this.oauthService.getIdToken();
      const accessToken = this.oauthService.getAccessToken();
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
          'x-access-token': accessToken,
        },
      });
    }
    return next.handle(request);
  }
}
