import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver, ComponentRef,
  ViewChild,
  ViewContainerRef,
  Type
} from '@angular/core';
import { Modal } from "@app/shared/components/modals/types/modal.model";

@Component({
  selector: 'app-modal',
  template: `<ng-template #modalContainer></ng-template>`,
})
export class ModalFactory {
  @ViewChild('modalContainer', { read: ViewContainerRef }) private modalContainer!: ViewContainerRef;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  createModal<T extends Modal>(component: Type<T>): ComponentRef<T> {
    this.modalContainer.clear();
    const factory: ComponentFactory<T> = this.componentFactoryResolver.resolveComponentFactory(component);
    return this.modalContainer.createComponent(factory, 0);
  }
}
