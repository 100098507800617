import { TrackByFunction } from '@angular/core';

/**
 * Returns only the keys that are true. Similar to `Object.keys(obj)` except it only return the key if `obj[key] === true`
 * @param source the source object
 */
export const trueKeys = (source: { [key: string]: boolean }): string[] =>
  Object.entries(source)
    .filter(([_, v]) => v)
    .map(([k]) => k);

export const trackByKey = <T>(key: keyof T): TrackByFunction<T> => (
  _index,
  item
) => item[key];

export const trackByIndex: TrackByFunction<unknown> = (index) => index;
