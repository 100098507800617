import { Component, Input } from '@angular/core';
import { AccountService } from '@app/core/auth/account.service';
import { trackByIndex } from '@app/shared/helpers/object.helpers';
import { NavLink } from '@app/shared/types/links';
import { environment } from '@env/environment';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';
import { Router } from "@angular/router";
import { HomePageAccessManager } from "@app/shared/types/home-page-access-manager.service";
import { admin_role_access } from "@app/shared/types/user";

export const INTERNAL_LOGO = '/assets/images/logo/logo-izi.png';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: '',
    },
  ],
})
export class HeaderComponent {
  canShowLights = environment.name !== 'prod';
  shouldShowLights = true;
  @Input() logo = INTERNAL_LOGO;
  @Input() hasButtons = false;
  @Input() navButtons!: NavLink[];
  @Input() fixed = true;
  username: string;
  isAdmin = false;

  constructor(private accountService: AccountService,
              private router: Router,
              private homePageAccessManager: HomePageAccessManager) {
    this.username = `${this.accountService?.claims?.firstName} ${this.accountService?.claims?.lastName}`;
    this.isAdmin = admin_role_access.includes(this.accountService?.currentUser?.role);
  }

  trackByIndex = trackByIndex;

  logout(): void {
    this.accountService.logout();
  }

  goToMainPage() {
    this.homePageAccessManager.accessHomePage();
  }

  redirectToAdminPage() {
    this.router.navigate(['/administration/users']);
  }
}
