<app-generic-redirection
  [title]="'page.not-found.title'"
  subTitle="page.not-found.error-title"
  illustration="/assets/images/not-found.svg"
  contentText="page.not-found.error-message"
  [redirectionLink]="redirection"
  redirectionLinkText="page.not-found.buttons.back-home"
  [externalRedirectionLink]="supportEmailRef"
  externalRedirectionLinkText="page.not-found.buttons.contact-support"
>
</app-generic-redirection>
