<bpi-styled-modal-dialog
  [title]="confirmModalInfo.title"
  [image]="'assets/images/feedback-confirmation-modal.svg'"
  [isOpen]="modalInstance.isOpen"
  [closeable]="true"
  [primaryButton]="confirmModalInfo.validation"
  (onConfirm)="close(true)"
  (onSecondary)="close()"
  (onClose)="close()"
>
  <ng-container *ngIf="confirmModalInfo && confirmModalInfo.messages">
    <div class="modal-content-message" *ngFor="let message of confirmModalInfo.messages">
      <p> {{ message.title }} {{ message.message }}</p>
    </div>
  </ng-container>
</bpi-styled-modal-dialog>
