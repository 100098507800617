import { Component } from '@angular/core';
import { HomePageAccessManager } from "@app/shared/types/home-page-access-manager.service";

@Component({
  selector: 'app-home-component',
  template: '',
})
export class HomeComponent {

  constructor(private homePageAccessManager: HomePageAccessManager) {}

  ngOnInit() {
    this.homePageAccessManager.accessHomePage();
  }
}
