import { Component, Input } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AttachmentUrlResolverService } from '@app/shared/services/attachment-url-resolver/attachment-url-resolver.service';
import { DetailsTypesService } from '@app/shared/services/details-types/details-types.service';
import {
  Attachment,
  DetailAttachment,
  DetailItem,
} from '@app/shared/types/details';
import { defer, iif, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.scss'],
})
export class DetailComponent {
  hasValue!: boolean;
  detail!: DetailItem;
  downloadUrl$!: Observable<SafeUrl>;
  detailTranslocoPath: string | undefined;

  @Input() set detailItem(detail: DetailItem) {
    const isDocument = this.typeService.isDocumentDetail(detail);
    this.hasValue =
      (isDocument && (detail as DetailAttachment).value.downloadLink !== '') ||
      (!isDocument && detail.value !== undefined);
    if (isDocument) {
      const value = detail.value as Attachment;
      const attachmentIziCode = value.iziAttachmentCode;
      this.downloadUrl$ = iif(
        () => this.hasValue,
        defer(() =>
          this.attachmentUrlResolverService
            .resolveUrls(
              detail.value as Attachment,
              attachmentIziCode as string
            )
            .pipe(map((url) => this.sanitizeUrl(url)))
        ),
        of()
      );
    }
    this.detail = { ...detail };
    this.detailTranslocoPath = detail.typeOfDetail;
  }

  constructor(
    public typeService: DetailsTypesService,
    private domSanitizer: DomSanitizer,
    private attachmentUrlResolverService: AttachmentUrlResolverService
  ) {}

  sanitizeUrl(url: string): SafeUrl {
    return this.domSanitizer.bypassSecurityTrustUrl(url);
  }
}
