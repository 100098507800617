import { Component, Input } from '@angular/core';
import {
  ContractStatus,
  ContractStatusDot,
  CONTRACT_STATUS_DOT,
} from '@app/shared/types/contract-status';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent {
  status!: ContractStatusDot;
  @Input()
  set contractStatus(value: ContractStatus) {
    this.status = CONTRACT_STATUS_DOT[value];
  }
}
