import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-generic-redirection',
  templateUrl: './generic-redirection.component.html',
  styleUrls: ['./generic-redirection.component.scss'],
})
export class GenericRedirectionComponent {
  @Input() title = '';
  @Input() subTitle = '';
  @Input() redirectionLink?: string[];
  @Input() externalRedirectionLink?: string;
  @Input() tertiaryLink = '';
  @Input() illustration = '';
  @Input() contentText = '';
  @Input() redirectionLinkText = '';
  @Input() externalRedirectionLinkText = '';
  @Input() tertiaryLinkText = '';
  @Input() vertical = false;
  @Input() centered = false;
}
