// eslint-disable-next-line no-shadow
export const enum AlertType {
  RED,
  ORANGE,
}

// eslint-disable-next-line no-shadow
export const enum AlertTypeColors {
  RED = '#E02020',
  ORANGE = '#FFCD00',
  GREEN = '#399A53',
  GRAY = '#BEBEBE',
  DEFAULT = '#000'
}

export const ALERT_TYPES: { [type in AlertType]: AlertTypeColors } = {
  [AlertType.RED]: AlertTypeColors.RED,
  [AlertType.ORANGE]: AlertTypeColors.ORANGE,
};
