import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
})
export class NotFoundComponent {
  redirection = ['/'];
  supportEmailRef = `mailto:${environment.support.email}`;
}
