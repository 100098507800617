<bpi-styled-modal-dialog
  [title]="dropdownModal.title"
  [image]="'assets/images/feedback-confirmation-modal.svg'"
  [isOpen]="modalInstance.isOpen"
  [closeable]="true"
  [primaryButton]="dropdownModal.validation"
  [secondaryButton]="dropdownModal.cancellation ? dropdownModal.cancellation : ''"
  (onConfirm)="close(itemChoice.value)"
  (onSecondary)="close()"
  (onClose)="close()"
>
  <div class="select-user">
    <bpi-styled-select *ngIf="dropdownModal && dropdownModal.items"
                       [choices]="dropdownModal.items"
                       [inputFormControl]="itemChoice"
                       [hideErrorMessage]="true">
    </bpi-styled-select>
  </div>
</bpi-styled-modal-dialog>
