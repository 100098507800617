import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Attachment } from '@app/shared/types/details';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AttachmentUrlResolverService {
  private baseUrl = `${environment.apiBase}/api/v1/internal/documents`;
  constructor(private httpClient: HttpClient) {}

  resolveUrls(
    attachment: Attachment,
    attachmentIziCode: string
  ): Observable<string> {
    const url = `${this.baseUrl}/ged/links?ids=${attachment.attachmentId}&domaine=DocumentGarantieGestion`;
    const body = {
      documents: [
        {
          attachmentIziCode,
          documentId: attachment.attachmentId,
        },
      ],
    };
    return this.httpClient
      .post<{ items: { type: string; gedUrl: string }[] }>(url, body)
      .pipe(pluck('items', '0', 'gedUrl'));
  }
}
