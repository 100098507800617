import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidebar-card',
  templateUrl: './sidebar-card.component.html',
  styleUrls: ['./sidebar-card.component.scss'],
})
export class SidebarCardComponent {
  @Input() title!: string;
  @Input() titleAddition!: string;
}
