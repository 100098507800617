export class DateUtils {

  static addDays(date: Date, daysToAdd: number): Date {
    if (daysToAdd && !isNaN(daysToAdd)){
      date.setDate(date.getDate() + daysToAdd);
    }
    return date;
  }

  static isSameDate(date1: Date, date2: Date): boolean {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    return date1.getTime() === date2.getTime();
  }

  static compareDates(date1: Date, date2: Date): number {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    if (date1.getTime() > date2.getTime()) {
      return 1;
    } else if (date1.getTime() < date2.getTime()) {
      return -1;
    }
    return 0;
  }

  static daysBetweenDates(date1: Date, date2: Date): number {
    date1.setHours(0, 0, 0, 0);
    date2.setHours(0, 0, 0, 0);

    const days = date1.getTime() - date2.getTime();

    return Math.ceil(days / (1000 * 3600 * 24));
  }

}
