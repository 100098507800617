import { Component, Input, OnInit } from '@angular/core';
import {
  getGroupPaymentSatusLabel,
  GroupPayment, StatusGroupPayment, TypeGroupPayment
} from "@app/shared/types/group-payment";
import { Router } from "@angular/router";
import { StepStatus } from "@app/shared/components/steps-validation/steps-validation.component";

@Component({
  selector: 'app-select-groups-payment-card',
  templateUrl: './select-groups-payment-card.component.html',
  styleUrls: ['./select-groups-payment-card.component.scss']
})
export class SelectGroupsPaymentCardComponent implements OnInit {
  @Input() selectGroupPayment!: GroupPayment;
  groupPaymentSatusLabel!: StepStatus;
  statusGroupPayment = [StatusGroupPayment.PAYE, StatusGroupPayment.VALD];
  typeGroupPayment = TypeGroupPayment;
  status = StatusGroupPayment;

  constructor(private router: Router) {}

  ngOnInit(): void {
    this.groupPaymentSatusLabel = getGroupPaymentSatusLabel(this.selectGroupPayment)
  }

  navigateToDtail() {
    this.router.navigate(['/groups-payment/' + this.selectGroupPayment.groupPaymentId]);
  }
}
