import { Injectable } from '@angular/core';
import { DetailDataType } from '@app/shared/types/text-display-type';

import {
  DetailDate,
  DetailAttachment,
  DetailItem,
  DetailMultipleChoice,
  DetailValue,
} from '../../types/details';

Injectable({
  providedIn: 'root',
});
export class DetailsTypesService {
  isDocumentDetail(detail: DetailItem): detail is DetailAttachment {
    return (detail as DetailAttachment).dataType === DetailDataType.ATTACHMENT;
  }

  isMultipleChoiceDetail(detail: DetailItem): detail is DetailMultipleChoice {
    return (
      (detail as DetailMultipleChoice).dataType ===
      DetailDataType.MULTIPLE_CHOICES
    );
  }

  isDateDetail(detail: DetailItem): detail is DetailDate {
    return (detail as DetailDate).dataType === DetailDataType.DATE;
  }

  isValueDetail(detail: DetailItem): detail is DetailValue {
    return (
      (detail as DetailValue).dataType === undefined ||
      !(
        (detail as DetailAttachment).dataType === DetailDataType.ATTACHMENT ||
        (detail as DetailMultipleChoice).dataType ===
          DetailDataType.MULTIPLE_CHOICES ||
        (detail as DetailDate).dataType === DetailDataType.DATE
      )
    );
  }
}
