// eslint-disable-next-line no-shadow
export enum UserRole {
  ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_HEALTHY_MANAGER = 'ROLE_HEALTHY_MANAGER',
  ROLE_JUNIOR_LAWYER = 'ROLE_JUNIOR_LAWYER',
  ROLE_SENIOR_LAWYER = 'ROLE_SENIOR_LAWYER',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_ACCOUNTANT = 'ROLE_ACCOUNTANT',
  ROLE_DEFAULT = ''
}
export interface User {
  role: UserRole;
  selfcareId: string;
  firstName: string;
  lastName: string;
  email: string;
}

export const claims_role_access = [
  UserRole.ROLE_SUPER_ADMIN,
  UserRole.ROLE_ADMIN,
  UserRole.ROLE_MANAGER,
  UserRole.ROLE_JUNIOR_LAWYER,
  UserRole.ROLE_SENIOR_LAWYER
];

export const claims_lawyer_access = [
  UserRole.ROLE_SUPER_ADMIN,
  UserRole.ROLE_MANAGER,
  UserRole.ROLE_JUNIOR_LAWYER,
  UserRole.ROLE_SENIOR_LAWYER
];

export const USER_ROLE_TRANSLATION: {
  [type in string]: string;
} = {
  [UserRole.ROLE_SUPER_ADMIN.toString()]: "admin.user.user-roles.super-admin",
  [UserRole.ROLE_ADMIN.toString()]: "admin.user.user-roles.admin",
  [UserRole.ROLE_MANAGER.toString()]: "admin.user.user-roles.manager",
  [UserRole.ROLE_SENIOR_LAWYER.toString()]: "admin.user.user-roles.senior-lawyer",
  [UserRole.ROLE_JUNIOR_LAWYER.toString()]: "admin.user.user-roles.junior-lawyer",
  [UserRole.ROLE_ACCOUNTANT.toString()]: "admin.user.user-roles.accountant",
  [UserRole.ROLE_HEALTHY_MANAGER.toString()]: "admin.user.user-roles.healthy-manager",
  [UserRole.ROLE_DEFAULT.toString()]: "admin.user.user-roles.default",
};

export const groups_payment_role_access = [UserRole.ROLE_SUPER_ADMIN, UserRole.ROLE_ADMIN, UserRole.ROLE_ACCOUNTANT]
export const admin_role_access = [UserRole.ROLE_SUPER_ADMIN]
