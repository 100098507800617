import { Component } from '@angular/core';
import {ModalBody, Modal} from "@app/shared/components/modals/types/modal.model";

@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.scss',]
})
export class InformationModalComponent extends Modal {
  confirmModalInfo: ModalBody = {
    title: '',
    validation:''
  };

  onInjectInputs(inputs: ModalBody): void {
    if (inputs) {
      this.confirmModalInfo = inputs;
    }
  }
}
