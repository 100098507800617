import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '@app/shared/components/snackbar/snackbar.component';
import { concat, fromEvent, timer } from 'rxjs';
import { delay, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private snackBar: MatSnackBar) {}

  open(message: string, theme: 'success' | 'error' = 'success') {
    const config: MatSnackBarConfig = {
      duration: undefined,
      verticalPosition: 'bottom',
      data: message,
      panelClass:
        theme === 'success' ? 'app-snackbar-success' : 'app-snackbar-error',
    };

    const snackBar = this.snackBar.openFromComponent(SnackbarComponent, config);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const sbRef = snackBar.containerInstance._elementRef.nativeElement;

    const timeOut$ = timer(6000);
    const mouseEnter$ = fromEvent(sbRef, 'mouseenter');
    const mouseLeave$ = fromEvent(sbRef, 'mouseleave').pipe(delay(1000));

    const mouseLeaveOrTimeout$ = concat(
      timeOut$.pipe(takeUntil(mouseEnter$)),
      mouseLeave$
    );

    mouseLeaveOrTimeout$
      .pipe(takeUntil(snackBar.afterDismissed()))
      .subscribe((r) => snackBar.dismiss());
  }

  showSuccessMessage(message: string): void {
    this.open(message, 'success');
  }

  showErrorMessage(message: string): void {
    this.open(message, 'error');
  }

  close() {
    this.snackBar.dismiss();
  }
}
