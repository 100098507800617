<app-generic-redirection
  subTitle="signin-redirection.subtitle"
  illustration="/assets/images/redirection.svg"
  externalRedirectionLink="/"
  externalRedirectionLinkText="signin-redirection.link-text"
  [vertical]="true"
  [centered]="true"
>
</app-generic-redirection>

