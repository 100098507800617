import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toMonthDate'
})
export class ToMonthDatePipe implements PipeTransform {
  monthNames: string[] = [
    "Janvier", "Février", "Mars", "Avril",
    "Mai", "Juin", "Juillet", "Août",
    "Septembre", "Octobre", "Novembre", "Décembre"
  ];

  transform(dateToFormat: string): string {
    const splitedDate = dateToFormat.split('-');
    return `${this.monthNames[parseInt(splitedDate[1], 10) - 1]} ${splitedDate[0]}`;
  }
}
