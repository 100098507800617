import { Injectable } from '@angular/core';
import { Claims } from '@app/core/auth/account.types';
import { SharedInfoService } from '@app/shared/services/shared-info/shared-info.service';
import { User, UserRole } from '@app/shared/types/user';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';

// @debt SHAREABLE:1 "jlenglet: should use a shared version of Auth service, with a custom service for partner user account"
@Injectable({
  providedIn: 'root',
})
export class AccountService {
  currentUser!: User;
  isManager!: boolean;

  constructor(
    private oauthService: OAuthService,
    private sharedInfoService: SharedInfoService
  ) {}

  isAuthenticatedIsam(): boolean {
    return this.oauthService.hasValidIdToken();
  }

  logout(): void {
    this.oauthService.logOut();
  }

  getCurrentUser(): Observable<User> {
    return this.sharedInfoService.getCurrentUser().pipe(
      take(1),
      tap((currentUser) => {
        this.currentUser = currentUser;
        this.isManager = currentUser.role === UserRole.ROLE_MANAGER || currentUser.role === UserRole.ROLE_SUPER_ADMIN;
      })
    );
  }

  get claims(): Claims {
    return this.oauthService.getIdentityClaims() as Claims;
  }

  get email(): string {
    return this.claims.emailAddress;
  }

  get mat(): string {
    return this.claims.mat.toLowerCase();
  }
}
