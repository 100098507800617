import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-arrow-button',
  templateUrl: './toggle-arrow-button.component.html',
  styleUrls: ['./toggle-arrow-button.component.scss'],
})
export class ToggleArrowButtonComponent {
  @Input() label?: string;
  @Input() labelHide?: string;
  @Input() icon?: string;
  @Input() shown = false;
  @Output() toggle = new EventEmitter();
}
