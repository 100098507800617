import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ClaimType } from '@app/shared/types/claim-types';
import { claims_lawyer_access, User } from '@app/shared/types/user';
import { InputChoice } from '@bpi/shared-components/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map, pluck, shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SharedInfoService {
  url = `${environment.apiBase}/api/v1/internal/claims`;

  users$!: Observable<InputChoice[]>;
  currentUser$!: Observable<User>;
  banks$!: Observable<InputChoice[]>;
  creditEventTypes$!: Observable<InputChoice[]>;

  constructor(private httpClient: HttpClient) {}

  getAvailableBanks(claimType: ClaimType[]): Observable<InputChoice[]> {
    const url = `${this.url}/banks?claimType=${claimType.join(',')}`;
    if (!this.banks$) {
      this.banks$ = this.httpClient
        .get<{ items: InputChoice[] }>(url)
        .pipe(shareReplay(), pluck('items'));
    }

    return this.banks$;
  }

  // change of type
  getAvailableLawyerUsers(): Observable<InputChoice[]> {
    const url = `${this.url}/users`;
    if (!this.users$) {
      this.users$ = this.httpClient.get<{ items: User[] }>(url).pipe(
        shareReplay(),
        pluck('items'),
        map(users => { return users.filter(user => claims_lawyer_access.includes(user.role)) }),
        map((users) =>
          users.map((user) => ({
            label: `${user.firstName} ${user.lastName}`,
            name: user.selfcareId,
          }))
        )
      );
    }
    return this.users$;
  }

  getCreditEventTypes(): Observable<InputChoice[]> {
    const url = `${this.url}/creditEventTypes`;
    if (!this.creditEventTypes$) {
      this.creditEventTypes$ = this.httpClient
        .get<{ items: InputChoice[] }>(url)
        .pipe(shareReplay(), pluck('items'));
    }

    return this.creditEventTypes$;
  }

  getCurrentUser(): Observable<User> {
    const url = `${environment.apiBase}/api/v1/internal/users/current`;
    if (!this.currentUser$) {
      this.currentUser$ = this.httpClient.get<User>(url).pipe(shareReplay());
    }
    return this.currentUser$;
  }
}
