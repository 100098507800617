<bpi-styled-modal-dialog
  [title]="confirmModalInfo.title"
  [image]="'assets/images/feedback-confirmation-modal.svg'"
  [isOpen]="modalInstance.isOpen"
  [closeable]="true"
  [primaryButton]="confirmModalInfo.validation"
  [secondaryButton]="confirmModalInfo.cancellation ? confirmModalInfo.cancellation : ''"
  (onConfirm)="close(true)"
  (onSecondary)="close()"
  (onClose)="close()"
>
  <div class="modal-content-message" *ngFor="let message of confirmModalInfo.messages">
    <p> {{ message.title }} {{ message.message }}</p>
  </div>
</bpi-styled-modal-dialog>
