import { Component, Input } from '@angular/core';
import { trackByIndex } from '@app/shared/helpers/object.helpers';
import { DetailItem } from '@app/shared/types/details';

@Component({
  selector: 'app-grid-details-list',
  templateUrl: './grid-details-list.component.html',
  styleUrls: ['./grid-details-list.component.scss'],
})
export class GridDetailsListComponent {
  @Input() gridDetailsListItems!: DetailItem[];
  @Input() separateSection = true;
  trackByIndex = trackByIndex;
}
