import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const ICONS_BASE_LOCATION = '/assets/icons';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  didRegisterIcons = false;

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  registerIcons() {
    this.registerHeaderIcons();

    this.didRegisterIcons = true;
  }

  private registerHeaderIcons() {
    const cockpitHeaderBase = `${ICONS_BASE_LOCATION}/header`;

    this.matIconRegistry.addSvgIcon(
      'izi_account',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${cockpitHeaderBase}/account.svg`
      )
    );

    this.matIconRegistry.addSvgIcon(
      'izzi_filter',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        `${ICONS_BASE_LOCATION}/filter.svg`
      )
    ),
      this.matIconRegistry.addSvgIcon(
        'alert',
        this.domSanitizer.bypassSecurityTrustResourceUrl(
          `${ICONS_BASE_LOCATION}/alert.svg`
        )
      );
  }
}
