import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
  selector: 'app-login-component',
  template: '',
})
export class LoginComponent implements OnInit {
  constructor(
    private oauthService: OAuthService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((params: Params) => {
      this.oauthService.initLoginFlow(params.redirectUri || '/');
    });
  }

  logout() {
    this.oauthService.logOut();
  }
}
