import { DatePipe } from '@angular/common';
import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { AmortizationTypes } from '@app/claims/types/amortissement';
import { Period } from '@app/claims/types/period';
import { SamplingStatus } from '@app/dashboard/types/claims';
import { AlternateTableItem } from '@app/shared/types/alternate-table';
import { DetailItem } from '@app/shared/types/details';
import { InfoCardItem } from '@app/shared/types/info-card-item';
import { DetailDataType } from '@app/shared/types/text-display-type';
import { TranslocoService } from '@ngneat/transloco';
import {
  TranslocoCurrencyPipe,
  TranslocoPercentPipe,
} from '@ngneat/transloco-locale';

@Directive({
  selector: '[appDisplayMode]',
})
export class DisplayModeDirective implements OnInit {
  @Input() appDisplayMode!: DetailItem | AlternateTableItem | InfoCardItem;
  @Input() detailTranslocoPath: string | undefined;

  constructor(
    private elementRef: ElementRef,
    private translocoCurrencyPipe: TranslocoCurrencyPipe,
    private translocoPercentPipe: TranslocoPercentPipe,
    private translocoService: TranslocoService,
    private datePipe: DatePipe
  ) {}

  ngOnInit() {
    if (this.appDisplayMode.value !== undefined) {
      const displayType = this.appDisplayMode.dataType;
      const value = `${this.appDisplayMode.value}`;
      let transformed = value;
      if (displayType !== null && displayType !== undefined) {
        switch (this.appDisplayMode.dataType) {
          case DetailDataType.CURRENCY:
            transformed = this.getValueAsCurrency(parseFloat(value));
            break;
          case DetailDataType.DATE:
            transformed = this.getValueAsDate(value);
            break;
          case DetailDataType.BOOLEAN:
            transformed = this.getValueAsBoolean(value === 'true');
            break;
          case DetailDataType.CLAIM_SAMPLE:
            transformed = this.displaySamplingStatus(value);
            break;
          case DetailDataType.PERCENTAGE:
            transformed = this.getValueAsPercentage(parseFloat(value));
            break;
          case DetailDataType.AMORTIZATION:
            transformed = this.displayAmortizationMode(value as AmortizationTypes);
            break;
          case DetailDataType.PERIOD:
            transformed = this.displayPeriod(value as Period);
            break;
          case DetailDataType.MONTH_DURATION:
            transformed = this.displayDuration(value, `${this.detailTranslocoPath}.duration.month`);
            break;
        }
        this.elementRef.nativeElement.innerHTML = transformed;
      }
    }
  }

  getValueAsCurrency = (value: number): string => {
    return this.translocoCurrencyPipe.transform(value, 'symbol', {
      minimumFractionDigits: 0,
    });
  };

  getValueAsDate = (value: string): string => {
    return this.datePipe.transform(value, 'dd-MM-yyyy') as string;
  };

  getValueAsPercentage = (value: number): string => {
    return this.translocoPercentPipe.transform(value, {
      maximumFractionDigits: 3,
    });
  };

  getValueAsBoolean = (value: boolean): string => {
    const basePath = this.detailTranslocoPath + '.boolean';
    const _value = value ? `${basePath}.yes` : `${basePath}.no`;
    return this.translocoService.translate(_value);
  };

  displaySamplingStatus = (value: string): string => {
    const isEqual = value.toUpperCase() === SamplingStatus.SAMPLED;
    return this.getValueAsBoolean(isEqual);
  };

  displayAmortizationMode = (value: AmortizationTypes): string => {
    switch (value) {
      case AmortizationTypes.IN_FINE:
        return  this.translocoService.translate<string>(`${this.detailTranslocoPath}.amortization.in-fine`).toUpperCase();
        break;
      case AmortizationTypes.ECHEANCE_CAPITAL:
        return  this.translocoService.translate<string>(`${this.detailTranslocoPath}.amortization.echeance-capitale`).toUpperCase();
        break;
      case AmortizationTypes.ECHEANCE_CONSTANT:
        return  this.translocoService.translate<string>(`${this.detailTranslocoPath}.amortization.echeance-constante`).toUpperCase();
        break;
      default:
        return '';
    }
  };

  displayPeriod = (value: Period): string => {
    switch (value) {
      case Period.ANNUAL:
        return  this.translocoService.translate<string>(`${this.detailTranslocoPath}.period.annual`).toUpperCase();
        break;
      case Period.MONTHLY:
        return  this.translocoService.translate<string>(`${this.detailTranslocoPath}.period.mensual`).toUpperCase();
        break;
      default:
        return '';
    }
  };

  displayDuration = (value: string, periodTranslocoKey: string): string => {
    return `${value} ${this.translocoService.translate(periodTranslocoKey)}`;
  };
}
