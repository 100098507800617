import { PAGE_URL } from '@app/app.constants';
import { environment } from '@env/environment';
import { AuthConfig } from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {
  // Url of the Identity Provider
  issuer: environment.sso.issuer,

  // The SPA's id. The SPA is registered with this id at the auth-server
  clientId: environment.sso.clientId,

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/sso/code',

  // URL of the SPA to redirect the user after silent refresh
  silentRefreshRedirectUri: window.location.origin + '/sso/code',

  // URL to redirect after the user has logged out
  postLogoutRedirectUri: window.location.origin + '/login',

  // URL to log out
  logoutUrl: `${environment.sso.issuer}/pkmslogout?filename=logoutBPI.html&post_logout_redirect_uri=${window.location.origin}/${PAGE_URL.SIGNIN}`,

  responseType: 'code',

  timeoutFactor: 0.3,

  useSilentRefresh: true,

  scope: 'openid profile email',
};
