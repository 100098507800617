import { Component } from '@angular/core';
import { FormControl } from "@angular/forms";
import { Modal, ModalBody } from "@app/shared/components/modals/types/modal.model";

@Component({
  selector: 'app-dropdown-modal',
  templateUrl: './dropdown-modal.component.html',
  styleUrls: ['./dropdown-modal.component.scss']
})
export class DropdownModalComponent extends Modal {
  dropdownModal: ModalBody = {
    title: '',
    items: [],
    validation:'',
    cancellation:''
  };
  itemChoice = new FormControl('');

  onInjectInputs(inputs: ModalBody): void {
    if (inputs) {
      this.dropdownModal = inputs;
    }

    if (this.dropdownModal && this.dropdownModal.items) {
      this.itemChoice.patchValue(this.dropdownModal.items[0].name)
    }
  }

}
