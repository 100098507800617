import { InputChoice } from "@bpi/shared-components/core";
import { Observable, Subject } from "rxjs";
import { ComponentRef } from "@angular/core";
import { ModalFactory } from "@app/shared/components/modals/factory/modal-factory.component";


export abstract class Modal {
  modalInstance!: ModalRef;

  abstract onInjectInputs(inputs: ModalBody): void;

  close(output?: unknown): void {
    this.modalInstance.close(output);
  }

  dismiss(output?: unknown): void {
    this.modalInstance.dismiss(output);
  }
}

export class ModalRef {
  private result: Subject<unknown> = new Subject<unknown>();
  isOpen!: boolean;

  constructor(
    private modalContainer: ComponentRef<ModalFactory>,
    private modal: ComponentRef<Modal>,
  ) {
    this.isOpen = true;
    this.modal.instance.modalInstance = this;
  }

  close(output?: unknown) {
    this.result.next(output);
    this.isOpen = false;
    this.destroy();
  }

  dismiss(output: unknown) {
    this.result.error(output);
    this.destroy();
  }

  onResult(): Observable<unknown> {
    return this.result.asObservable();
  }

  private destroy() {
    this.modal.destroy();
    this.modalContainer.destroy();
    this.result.complete();
  }
}

export type ModalBody = {
  title: string;
  messages?: Message[];
  items? : InputChoice[];
  validation: string;
  cancellation?: string;
};

export interface Message {
  title?: string;
  message: string | number;
}
