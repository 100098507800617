import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { PAGE_URL } from '@app/app.constants';
import { AccountService } from '@app/core/auth/account.service';
import {Observable, of} from "rxjs";
import {catchError, map} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class PermissionAccessGuard implements CanActivate {

  constructor(public accountService: AccountService, public router: Router){}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {
    return this.accountService.getCurrentUser().pipe(
      map(currentUser => {
        if (currentUser) {
          if (route.data.roles && route.data.roles.indexOf(currentUser.role) === -1) {
            this.router.navigate([PAGE_URL.ACCESS_DENIED]);
            return false;
          }else{
            return true;
          }
        } else {
          this.router.navigate([PAGE_URL.ACCESS_DENIED]);
          return false;
        }
      }),
      catchError(() => {
        this.router.navigate([PAGE_URL.ACCESS_DENIED]);
        return of(false);
      })
    );
  }
}
